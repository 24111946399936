import React from 'react'
import './main.scss'
import StartSection from './Main-Components/Start-Section/Start-Section'
import MainTop from './Main-Components/Main-Top/Main-Top'
import Coaches from './Main-Components/Coaches/Coaches'
import Grafik from './Main-Components/Grafik/Grafik'
import Price from './Main-Components/Price/Price.jsx'
import Map from './Main-Components/Map/Map.jsx'

export default function Main() {
  return (
    <div className='main'>
        <MainTop></MainTop>
        <StartSection></StartSection>
        <Coaches></Coaches>
        <Grafik></Grafik>
        <Price></Price>
        <Map></Map>
    </div>
  )
}
