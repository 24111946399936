import React from 'react'
import './startSection.scss'
import img1 from '../../../../img/background/image2.png'
import img2 from '../../../../img/background/image3.png'

export default function StartSection() {
  return (
    <div id='start' className='main-second-section'>
        <div className='main-second-section-background'>
            <div className='back-img-one'>
                <img src={img1} alt="" />
            </div>
            {/* <div className='back-img-two'>
                <img src={img2} alt="" />
            </div> */}
        </div>
        <div className='par-fc'>
            <div className='par-fc-block'>
                <h1>Klubs Fun Catchers</h1>
                <p>
                    Fun Catchers Club tika dibināts 2020. gadā
                    <br />Kluba galvenais mērķis ir piesaistīt cilvēkus (bērnus, pusaudžus un pieaugušos) aktīvam dzīvesveidam.
                    <br />Mūsu uzdevums ir likt jums iemīlēt sportu.Laba kompānija, patīkami treneri un ērta vide palīdzēs iegrimt sporta pasaulē.
                    <br />Šobrīd mūsu klubs piedāvā nodarbības Vispārējā fiziskajā sagatavošanā, Fitnesa, CrossFita, Spēka treniņos trenažieru zālē, Kikboksa, Taekwondo, Boksu.
                    <br />Nav vecuma ierobežojumu.
                    <br />
                    <br />Tiem, kuri vēlas ne tikai sportot, bet arī piedalīties sacensībās un sasniegt augstus rezultātus, piedāvājam iespēju piedalīties Kikboksa, 
                    <br />Boksā turnīros, dažādos skrējienos, no (1 km līdz maratonam), kā arī dažādus spēka skrējienus un turnirus.
                </p>
            </div>
        </div>
    </div>
  )
}
