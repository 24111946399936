import React, {useState, useEffect} from 'react';
import FighterCard from './FighterCard/FighterCard'
import { FighterList } from './FighterList/FighterList';
import './fighter.scss'
import PasswordForm from '../Password/PasswordForm';

export default function Fighter() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    setAuthenticated(isAuthenticated);
  }, []);

  const handlePasswordCorrect = () => {
    setAuthenticated(true);
  };
  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    setAuthenticated(false);
  };

      // Порядок сортировки категорий
  const categoriesOrder = [
    'Pieagušie 19+',
    'Juniori 16-18 gadi',
    'Vecākie kadeti 13-15 gadi',
    'Jaunākie kadeti 10-12 gadi',
    'Bērni 7-9 gadi',
    "Jaunākie sportisti",
    'Неопределено',
  ];

  // Сортируем список бойцов
  const sortedFighters = FighterList.sort((a, b) => {
    return (
      categoriesOrder.indexOf(a.category) - categoriesOrder.indexOf(b.category)
    );
  });
  // Группируем спортсменов по категориям
  const groupedFighters = FighterList.reduce((acc, fighter) => {
    if (!acc[fighter.category]) {
      acc[fighter.category] = [];
    }
    acc[fighter.category].push(fighter);
    return acc;
  }, {});

  return (
    <div className='fighter-category-container'>
        <div>
          <h2>Informācija atvērta tikai klubu biedriem</h2>
        </div>
            {!authenticated ? (
        <PasswordForm onPasswordCorrect={handlePasswordCorrect} />
      ) : (
        <div>
      <div className='fighter-category-info-conteiner'>
        {/* <div className='fighter-category-info-tatami'>
          Tatami
          <div className='fighter-category-info-tatami-open'>
              <ul>
                <li className='fighter-category-info-CH'>Children (CH) (7, 8, 9 years old)
                  <div>
                    <div>
                      <p className='fighter-category-info-tatami-title'>
                        Boys / Girls
                      </p>
                      <p className='fighter-category-info-weight'>
                        -18 <br />
                        -21 <br />
                        -24 <br />
                        -27 <br />
                        -30 <br />
                        -33 <br />
                        -36 <br />
                        +36 <br />
                      </p>
                    </div>
                  </div>
                </li>
                <li className='fighter-category-info-YC'>Younger Cadets (YC) (10, 11, 12 years old) 
                <div>
                    <div>
                      <p className='fighter-category-info-tatami-title'>
                        Boys / Girls
                      </p>
                      <p className='fighter-category-info-weight'>
                        -28 <br />
                        -32 <br />
                        -37 <br />
                        -42 <br />
                        -47 <br />
                        -47 <br />
                      </p>
                    </div>
                  </div>
                </li>
                <li>Older Cadets (OC) (13, 14, 15 years old)</li>
                <li>Juniors (J) (16, 17, 18 years old)</li>
                <li>Seniors (S) (from age 19 to 40)</li>
                <li>Master Class (Veterans) (V) (from age 41 to 55 years)</li>
              </ul>
          </div>
        </div> */}
        {/* <div className='fighter-category-info-ring'>
          Ring
        </div> */}
      </div>
      <div>
        <h2>Sportisti</h2>
      </div>
      {/* Отображаем группы по категориям */}
      {Object.entries(groupedFighters).map(([category, fighters, discipline, activityRate]) => (
        <div key={category} className='fighter-category-block'>
          {/* Название категории */}
          <h3>
            {category}
          </h3>
          <hr className='line-for-fighter'/>
          {/* Карточки спортсменов */}
          <div className='fighter-card-block'>
            {fighters.map((fighter, index) => (
              <FighterCard
                key={index}
                img={fighter.img}
                fighter={fighter.fighter}
                discipline={fighter.discipline}
                activityRate={fighter.activityRate}
                wight={authenticated ? fighter.wight : 'Требуется пароль'}
                // category={fighter.category}
              />
            ))}
          </div>
        </div>
      ))}
      </div>
      )}
      <button onClick={handleLogout}>Выйти</button>
    </div>
    
  );
}
