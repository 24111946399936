import React, { useState, useEffect } from 'react';

export default function PasswordForm({ onPasswordCorrect }) {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'FC2024!') {
      localStorage.setItem('isAuthenticated', 'true'); // Сохраняем состояние пароля
      onPasswordCorrect();
    } else {
      setError('Неверный пароль');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Введите пароль"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Войти</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  );
}
