import './App.css';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import Header from './Components/Header/Header';
import Main from './Components/Main/Main';
import Footer from './Components/Footer/Footer';
import Map from './Components/Main/Main-Components/Map/Map';
import Fighter from './Pages/fighters/Fighter';
import Rate from './Pages/Rate/Rate';

function App() {
  return (
    <>
      <HashRouter>
        <Header />
        <Routes>
          {/* Перенаправление на /main */}
          <Route path='/' element={<Navigate to="/main" />} />
          
          {/* Main как контейнер для страниц */}
          <Route path='/main' element={<Main />} />
          <Route path='/fighter' element={<Fighter />} />
          <Route path='/map' element={<Map />} />
          <Route path='/rate' element={<Rate />} />
        </Routes>
        <Footer />
      </HashRouter>
    </>
  );
}

export default App;
