import React from 'react'
import './mainTop.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import kikKid1 from './img/kik-kids-1.jpg'
import kikKid2 from './img/kik-kids-2.jpg'
import kikKid3 from './img/kik-kids-3.jpg'
import team1 from './img/team-1.jpg'
import team2 from './img/team-2.jpg'
import team3 from './img/team-3.jpg'
import team4 from './img/team-4.jpg'
import team5 from './img/team-5.jpg'
import team6 from './img/team-6.jpg'

export default function MainTop() {
    const images = [
        {alt:'kikKid1', src: kikKid1, title: 'Berni', className:'kikKid1'},
        {alt:'kikKid2,', src: kikKid2, title: 'Berni', className:'kikKid2'},
        {alt:'kikKid3', src: kikKid3, title: 'Berni'}, 
        {alt:'team1', src: team1, title: 'Komanda', className:'team1'},
        {alt:'team2', src: team2, title: 'Komanda', className:'team2'},
        {alt:'team3', src: team3, title: 'Komanda', className:'team3'},
        {alt:'team4', src: team4, title: 'Komanda', className:'team4'},
        {alt:'team5', src: team5, title: 'Komanda', className:'team5'},
        {alt:'team6', src: team6, title: 'Komanda', className:'team6'},
    ] 

    const settings = {
        dots: true,                // Показывает точки навигации под слайдами
        infinite: true,            // Бесконечный цикл
        speed: 500,                // Скорость анимации
        slidesToShow: 1,           // Количество отображаемых слайдов
        slidesToScroll: 1,         // Количество слайдов при прокрутке
        autoplay: true,            // Автоматическая прокрутка
        autoplaySpeed: 3000,       // Скорость автопрокрутки в миллисекундах
      };
    
    return (
    <div className="carousel-container">
        <Slider {...settings}>
        {images.map((image, index) => (
            <div key={index}>
            <img src={image.src} alt={image.alt} className={`carousel-image ${image.className}`} />
            <h2>{image.title}</h2>
        </div>
        ))}
        </Slider>
    </div>
    );
}
