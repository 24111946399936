import Milana from "./img/Milana.jpg"
import boy1 from './img/Avatar-boy-1-removebg-preview.png'
import boy2 from './img/Avatar-boy-2-removebg-preview.png'
import boy3 from './img/Avatar-boy-3.png'
import boy4 from './img/Avatar-boy-4.png'
import boy5 from './img/Avatar-boy-5.png'
import boy6 from './img/Avatar-boy-6.png'
import boy7 from './img/Avatar-boy-7.png'
import girl1 from './img/Avatar-girl-1-removebg-preview.png'
import girl2 from './img/Avatar-girl-2-removebg-preview.png'
import girl3 from './img/Avatar-girl-3-removebg-preview.png'
import girl4 from './img/Avatar-girl-4.png'
import girl5 from './img/Avatar-girl-5.png'

  
export const assignCategory = (fighter) => {
    const currentYear = new Date().getFullYear();
    const birthYear = fighter.date ? parseInt(fighter.date.split(".")[2], 10) : null;
  
    if (!birthYear) return "Неопределено";
  
    const ageNextYear = currentYear - birthYear + 1;
    if (ageNextYear <= 6) return "Jaunākie sportisti";
    if (ageNextYear >= 7 && ageNextYear <= 9) return "Bērni 7-9 gadi";
    if (ageNextYear >= 10 && ageNextYear <= 12) return "Jaunākie kadeti 10-12 gadi";
    if (ageNextYear >= 13 && ageNextYear <= 15) return "Vecākie kadeti 13-15 gadi";
    if (ageNextYear >= 16 && ageNextYear <= 18) return "Juniori 16-18 gadi";
    if (ageNextYear >= 19) return "Pieagušie 19+";
  
    return "Неопределено";
  };

export const FighterList = [
    {img: Milana, sport: "Kikbokss", fighter: "Milana Loginova", place:"Tatami", discipline:"Kick-light, Light-Contact", date: '16.09.2007', wight: '-55',activityRate: '40'},
    {img: boy1, sport: "Kikbokss", fighter: "Edens Eduards Čepulis",  place:"Tatami", discipline:"Kick-light", date: '06.12.2006', wight: ''},
    {img: boy2, sport: "Kikbokss", fighter: "Matiss Krišjanos", place:"Tatami", discipline:"Kick-light, Light-Contact", date: '15.10.2008', wight: '-69', activityRate: '9'},
    {img: boy3, sport: "Kikbokss", fighter: "Oleksandr Skrypko", place:"Tatami", discipline:"Kick-light", date: '', wight: '-63'},
    {img: boy4, sport: "Kikbokss", fighter: "Matveii Nikitenko",  place:"Tatami", discipline:"Kick-light",date: '11.12.2006', wight: '-63', activityRate: '3'},
    {img: boy5, sport: "Kikbokss", fighter: "Romils Gaļičs", place:"Tatami", discipline:"Kick-light", date: '10.02.2012', wight: '+47', activityRate: '10'},
    {img: boy6, sport: "Kikbokss", fighter: "Artjoms Matissans", place:"Tatami", discipline:"Kick-light", date: '13.04.2012', wight: '-47', activityRate: '6'},
    {img: boy2, sport: "Kikbokss", fighter: "Yehor Komissarov", place:"Tatami", discipline:"Kick-light, Light-Contact", date: '10.02.2011', wight: '+47', activityRate: '3'},
    {img: boy3, sport: "Kikbokss", fighter: "Milans Banada", place:"Tatami", discipline:"Kick-light", date: '25.02.2012', wight: '52', activityRate: '13'},
    {img: girl4, sport: "Kikbokss", fighter: "Anna Kožukovska", place:"Tatami", discipline:"Body-Contact", date: '06.06.2016', wight: '-29', activityRate: '8'},
    {img: girl2, sport: "Kikbokss", fighter: "Emma Kožukovska", place:"Tatami", discipline:"Body-Contact", date: '05.06.2018', wight: '-23', activityRate: '8'},
    {img: boy4, sport: "Kikbokss", fighter: "Reino Kronbergs", place:"Tatami", discipline:"Body-Contact", date: '17.05.2017', wight: '-29' },
    {img: boy5, sport: "Kikbokss", fighter: "Ralfs Unda", place:"Tatami", discipline:"Body-Contact", date: '06.10.2015', wight: '-36' },
    {img: boy7, sport: "Kikbokss", fighter: "Dāvis Moisejs", place:"Tatami", discipline:"Body-Contact", date: '12.05.2016', wight: '+36', activityRate: '2'},
    {img: boy2, sport: "Kikbokss", fighter: "Davids Boiko", place:"Tatami", discipline:"Body-Contact", date: '28.06.2016', wight: '-29' },
    {img: boy4, sport: "Kikbokss", fighter: "Eduards Rivlins", place:"Tatami", discipline:"Body-Contact", date: '08.07.2018', wight: '' },
    {img: boy5, sport: "Kikbokss", fighter: "Roberts Žujam", place:"Tatami", discipline:"Body-Contact", date: '09.09.2018.', wight: '' },
    {img: boy1, sport: "Kikbokss", fighter: "Žans Banada", place:"Tatami", discipline:"Body-Contact, Kick-Light", date: '12.02.2015', wight: '+36', activityRate: '8'},
    {img: girl3, sport: "Kikbokss", fighter: "Polina Butorina", place:"Tatami", discipline:"Body-Contact", date: '21.12.2016', wight: '-32' },
    {img: girl1, sport: "Kikbokss", fighter: "Anastasija Butorina", place:"Tatami", date: '21.12.2018', wight: '42' },
    {img: boy2, sport: "Kikbokss", fighter: "Mihails Medvedskis", place:"Tatami", discipline:"Body-Contact", date: '25.01.2016', wight: '+36', activityRate: '4'},
    {img: boy3, sport: "Kikbokss", fighter: "Ivans Medvedskis", place:"Tatami", discipline:"Body-Contact", date: '14.07.2017', wight: '-29', activityRate: '2'},
    {img: boy4, sport: "Kikbokss", fighter: "Kirils Kutuzovs", place:"Tatami", date: '01.09.2016', wight: '' },
    {img: boy3, sport: "Kikbokss", fighter: "Arturs Kalvaks", place:"Tatami", date: '21.11.2021', wight: '' },
    {img: boy5, sport: "Kikbokss", fighter: "Marks Kalvaks", place:"Tatami", date: '09.11.2020', wight: '' },
    {img: boy6, sport: "Kikbokss", fighter: "Jānis Akmentiņš", place:"Tatami", date: '4.12.2020', wight: '' },
    {img: boy7, sport: "Kikbokss", fighter: "Jānis Urbanovičs", place:"Tatami", date: '14.10.2020', wight: '' },
    {img: boy1, sport: "Kikbokss", fighter: "Rudols Labsvardis", place:"Tatami", date: '2020', wight: '' },
    {img: boy1, sport: "Kikbokss", fighter: "Mareks Ispavskis", place:"Tatami", discipline:"Kick-light", date: '11.12.2008', wight: '-89', activityRate: '6'},
    
].map((fighter) => ({
    ...fighter,
    category: assignCategory(fighter), // Добавляем категорию на этапе создания списка
  }));