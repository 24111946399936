import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import './map.scss'

export default function Map() {
    const position = [56.922278, 24.377951];
  return (
    <div id='map' className='map-conteiner'>
        <MapContainer className='map-size' center={position} zoom={12.4}>
      {/* Подключаем карту OpenStreetMap */}
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {/* Добавляем маркер */}
      <Marker position={position}>
        <Popup>
            Sporta zale "Fun Gym"
            <br />Fun Catchers
            <br /> Zahārija Stopija iela 10, Ropažu novads, Stopiņu pagasts, Upeslejas
        </Popup>
      </Marker>
    </MapContainer>
    </div>
  )
}
