import React, { useState } from 'react';
import '../fighter.scss';

export default function FighterCard({ img, fighter, category, discipline, activityRate, wight }) {
  const [openFighterInfo, setOpenFighterInfo] = useState(false);

  const handleFighterInfo = (e) => {
    e.stopPropagation(); // Останавливаем всплытие события
    setOpenFighterInfo(!openFighterInfo);
  };

  const closeFighterInfo = (e) => {
    e.stopPropagation(); // Останавливаем всплытие события
    setOpenFighterInfo(false);
  };

  return (
    <div
      className={`fighter-card ${openFighterInfo ? 'active' : ''}`}
      onClick={() => setOpenFighterInfo(true)} // Открываем окно при клике на карточку
    >
      <div className="fighter-img">
        <img src={img} alt="" />
      </div>
      <div className="fighter-about">
        <h4>{fighter}</h4>
        <div className={`fighter-about-more ${openFighterInfo ? 'visible' : ''}`}>
          {/* Кнопка закрытия */}
          <div className="close-btn" onClick={closeFighterInfo}>X</div>
          {/* Информация о спортсмене */}
          <div className="fighter-img">
            <img src={img} alt="" />
          </div>
          
          <div>
          <p className="fighter-discipline">{discipline || "Vēl nav piedalījusies"}</p>
          <p className='fighter-activity-rate'>Reiringa skaits: {activityRate || "Nav datu"}</p>
          <p className="fighter-weight">Svara kategorija: {wight || "Nav datu"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}