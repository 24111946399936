import React from 'react'
import {Link} from 'react-router-dom'
import './footer.scss'
import LKF from './Color-logo_2x-100-removebg-preview.png'
import Wako from './Wako_page-0001-removebg-preview.png'
import fc from './fc-removebg-preview.png'
import rn from './Ropazu_nov_gerbonis_apl-removebg-preview.png'


export default function Footer() {
  return (
    <div  className='footer-conteriner'>
        <div className='footer-lkf'>
            <img src={LKF} alt="LKF" />
        </div>
        <div className='footer-wako'>
            <img src={Wako} alt="" />
        </div>
        <div id='contact' className='footer-kontakt-computer'>
            <h3><b>Biedrība "Fun Catchers"</b></h3>
            <p><b>Adrese:</b>Zahārija Stopija iela 10, Upeslejas, Ropažu novads, Stopiņu pagasts, LV-2118</p>
            <p><b>Tālrunis:</b>+371 29 427 994</p>
            <p><b>E-pasts:</b>funcatcherslv@gmail.com</p>
            {/* <Link to={'/fighter'}>lol</Link> */}
        </div>  
        <div className='footer-rn'>
            <img src={rn} alt="" />
        </div>
        <div className='footer-fc'>
            <img src={fc} alt="" />
        </div>
        <div id='contact-mobile' className='footer-kontakt-mobile'>
            <h3><b>Biedrība "Fun Catchers"</b></h3>
            <p><b>Adrese:</b>Zahārija Stopija iela 10, Upeslejas, Ropažu novads, Stopiņu pagasts, LV-2118</p>
            <p><b>Tālrunis:</b>+371 29 427 994</p>
            <p><b>E-pasts:</b>funcatcherslv@gmail.com</p>
        </div>
    </div>
  )
}
