import React from 'react'
import './coaches.scss'
import iljaK from './img/IljaK.jpg'
import nastjaK from './img/NastjaK.jpg'
import VikaD from './img/VikaD.jpg'
import sashaA from './img/SashaA.jpg'

export default function Coaches() {
  return (
    <>
    <h2 id='coaches'>Mūsu Treneri</h2>
    <div className='coaches'>
        <div className='coach-card'>
            {/* <h4>Treneris</h4> */}
            <div className='background-coach-img'>
                <img src={iljaK} alt="ilja-img" />
            </div>
            <h3>Iļja Kožukovskis</h3>
            {/* <button>Pierakstities</button> */}
        </div>
        <div className='coach-card'>
            {/* <h4>Treneris</h4> */}
            <div className='background-coach-img'>
                <img src={nastjaK} alt="nastja-img" />
            </div>
            <h3>Anastasija Kožukovska</h3>
            {/* <button>Pierakstities</button> */}
        </div>
        <div className='coach-card'>
            {/* <h4>Treneris</h4> */}
            <div className='background-coach-img'>
                <img src={VikaD} alt="vika-img" />
            </div>
            <h3>Viktorija Dangele</h3>
            {/* <button>Pierakstities</button> */}
        </div>
        <div className='coach-card'>
            {/* <h4>Treneris</h4> */}
            <div className='background-coach-img'>
            <img src={sashaA} alt="sasha-img" />
            </div>
            <h3>Aleksandrs Anisčenko</h3>
            {/* <button>Pierakstities</button> */}
        </div>
    </div>
    </>
  )
}
