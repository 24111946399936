import React from 'react';
import FighterCard from '../fighters/FighterCard/FighterCard';
import { FighterList } from '../fighters/FighterList/FighterList';
import SportistsRateCard from './SportistRateCard.jsx';
import '../fighters/fighter.scss'

export default function Rate() {

  // Сортируем список бойцов
  const sortedFighters = [...FighterList].sort((a, b) => {
    return (b.activityRate || 0) - (a.activityRate || 0);
  });

  // Группируем спортсменов по категориям
  const groupedFighters = FighterList.reduce((acc, fighter) => {
    if (!acc[fighter.category]) {
      acc[fighter.category] = [];
    }
    acc[fighter.category].push(fighter);
    return acc;
  }, {});

  return (
    <div className='rate-category-container'>
      <div>
        <h2>Reitings</h2>
      </div>
      {/* Отображаем группы по категориям */}
          <div className='fighter-card-block'>
            {sortedFighters.map((fighter, index) => (
              <SportistsRateCard
                key={index}
                img={fighter.img}
                fighter={fighter.fighter}
                discipline={fighter.discipline}
                activityRate={fighter.activityRate}
                // category={fighter.category}
              />
            ))}
          </div>
        </div>
  );
}
