import React, {useState, useEffect} from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import FcLogo from './Logo-design_FunCatchers_2020_Agness_black-removebg-preview.png'
import './header.scss'
import menu from './list.svg'

export default function () {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openClub, setOpenClub] = useState(false);
    const [openSports, setOpenSports] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const toggleClub = () => {setOpenClub(!openClub)}
    const toggleSports = () => setOpenSports(!openSports);


    const handleMenuOpen = () => {
      setIsMenuOpen(!isMenuOpen)
    }

    const closeAllMenus  = () => {
      setIsMenuOpen(false);
      setOpenClub(false); // Закрывает подменю тоже, если оно открыто
      setOpenSports(false);
    };

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 35) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToElement = (id) =>{
      const element = document.getElementById(id);
      if(element){
        element.scrollIntoView({behavior: 'smooth'});
      }
      closeAllMenus();
    }

    const handleNavigation = (id) => {
      if (location.pathname !== '/') {
        // Если не на главной странице, сначала переключаемся
        navigate('/');
        // После завершения перехода вызываем прокрутку
        setTimeout(() => scrollToElement(id), 100);
      } else {
        // Если уже на главной странице, просто скроллим
        scrollToElement(id);
      }
    };

  return (
    <div className={`header ${isScrolled ? 'fixed' : ''}`}>
        <div className='header-all-blocks'>
            <div className='left-block'>
                <div className='logo'>
                    <img src={FcLogo} alt="logo-fc" />
                </div>
            </div>
            <div className='right-block'>
                <div className='menu-block'>
                    <img 
                    className='mobile-menu-icon' 
                    src={menu} 
                    alt="" 
                    onClick={handleMenuOpen}
                    />
                    <ul className='menu-computer'>
                        <li onClick={() => handleNavigation("start")}><Link to={'/'}>Sakums</Link></li>
                        <li id='club'>Klubs
                          <ul className='submenu'>
                            <li className='sports-menu'>Sports
                              <ul id='kik-menu' className='submenu'>
                                <li> <Link to={'/fighter'}>Kikbokss</Link></li>
                              </ul>
                            </li>
                            <li><Link to={'/rate'}>Reitings</Link></li>
                          </ul>
                        </li>
                        <li onClick={() => handleNavigation("coaches")}>Treneri</li>
                        <li onClick={() => handleNavigation("calendar")}>Kalendars</li>
                        <li onClick={() => handleNavigation("price")}>Pakalpojumi</li>
                        <li onClick={() => handleNavigation("map")}>Karte</li>
                        <li onClick={() => handleNavigation("contact")}>Kontakti</li>
                    </ul>
                    <ul className={`menu-mobile ${isMenuOpen ? 'open': ''}`}>
                        <li onClick={closeAllMenus}><Link to={'/'}>Sakums</Link></li>
                        <li 
                        id='club' 
                        onClick={toggleClub}
                        className={openClub ? 'open' : ''}
                        >
                          Klubs
                            <ul className='submenu'>
                            <li 
                              className={`sports-menu ${
                                openSports ? 'open' : ''
                              }`}
                              onClick={(e) => {
                                  e.stopPropagation();
                                  toggleSports();
                              }}
                          >
                            Sports
                              <ul id='kik-menu' className='submenu'>
                                <li onClick={closeAllMenus}> <Link to={'/fighter'}>Kikbokss</Link></li>
                              </ul>
                            </li>
                            <li><Link to={'/rate'}>Reitings</Link></li>
                          </ul>
                        </li>
                        <li onClick={closeAllMenus}>
                                <Link to="#coaches">Treneri</Link>
                            </li>
                            <li onClick={closeAllMenus}>
                                <Link to="#calendar">Kalendārs</Link>
                            </li>
                            <li onClick={closeAllMenus}>
                                <Link to="#price">Pakalpojumi</Link>
                            </li>
                            <li onClick={closeAllMenus}>
                                <Link to="#map">Karte</Link>
                            </li>
                            <li onClick={closeAllMenus}>
                                <Link to="#contact-mobile">Kontakti</Link>
                            </li>
                    </ul>
                </div>
                <div className='language-block'></div>
            </div>
        </div>
    </div>
  )
}
