import React from 'react'
import './price.scss'

export default function Price() {
   
    const iljaPhoneNmber = '+37129427994';
    const firstComplekt = encodeURIComponent('Gribu nopirkt pakalpojuma komplektu 1')
    const whatsAppFirstComplekt = `https://wa.me/${iljaPhoneNmber}?text=${firstComplekt}`;
    const kikbokss = encodeURIComponent('Gribu pierakstīties uz kikboksa nodarbību')
    const whatsAppKik = `https://wa.me/${iljaPhoneNmber}?text=${kikbokss}`;
    const ChildThreeKikbokss = encodeURIComponent('Gribu pierakstīties uz bērnu nodarbību 3+')
    const whatsAppChildThreeKikbokss = `https://wa.me/${iljaPhoneNmber}?text=${ChildThreeKikbokss}`;
    const ChildSixKikbokss = encodeURIComponent('Gribu pierakstīties uz bērnu nodarbību 6+')
    const whatsAppChildSixKikbokss = `https://wa.me/${iljaPhoneNmber}?text=${ChildSixKikbokss}`;
    const Bokss = encodeURIComponent('Gribu pierakstīties uz Boksa nodarbību')
    const whatsAppBokss = `https://wa.me/${iljaPhoneNmber}?text=${Bokss}`;
    const Taekwondo = encodeURIComponent('Gribu pierakstīties uz Taekwondo nodarbību')
    const whatsAppTaekwondo = `https://wa.me/${iljaPhoneNmber}?text=${Taekwondo}`;
    const oneWalk = encodeURIComponent('Gribu pierakstīties uz 1 Apmeklejumu')
    const whatsAppOneWalk = `https://wa.me/${iljaPhoneNmber}?text=${oneWalk}`;
    const personal = encodeURIComponent('Gribu pierakstīties uz individuālu nodarbību')
    const whatsAppPersonal = `https://wa.me/${iljaPhoneNmber}?text=${personal}`;
    const nastjaPhoneNmber = '+37126897551';
    const Fitness = encodeURIComponent('Gribu pierakstīties uz fitnesu')
    const whatsAppFitness = `https://wa.me/${nastjaPhoneNmber}?text=${Fitness}`;
    
  return (
    <div id='price' className='price-conteiner'>
        <h2>Pakalpojumi</h2>
        <div className='price-list'>
            <div className='price-block'>
                <h2>Pakalpojuma komplekts 1</h2>
                <h4>25 Eur</h4>
                <h6>Tikai zāle</h6>
                <p>Neierobežota pieeja trenažieru zālei un boksa zālei patstāvīgam darbam.</p>
                <button onClick={() =>{window.location.href = whatsAppFirstComplekt}}><b>Pierakstīties</b></button>
            </div>
            <div className='price-block'>
                <h2>Pakalpojuma komplekts 2</h2>
                <h4>20 - 35 EUR</h4>
                <h6>Boks</h6>
                <p>20 EUR tikai boksa nodarbības</p>
                <p>35 Eur Boks + trenažieru zāle</p>
                <button onClick={() =>{window.location.href = whatsAppBokss}}><b>Pierakstīties</b></button>
            </div>
            <div className='price-block'>
                <h2>Pakalpojuma komplekts 3</h2>
                <h4>20 - 35 EUR</h4>
                <h6>Taekwondo</h6>
                <p>20 EUR tikai Taekwondo nodarbības</p>
                <p>35 Eur Taekwondo + trenažieru zāle</p>
                <button onClick={() =>{window.location.href = whatsAppTaekwondo}}><b>Pierakstīties</b></button>
            </div>
            <div className='price-block'>
                <h2>Pakalpojuma komplekts 4</h2>
                <h4>35 - 45 Eur</h4>
                <h6>Fitness</h6>
                <p>Fitnesa treniņš</p>
                <p>35 Eur Ja bērns iet uz nodarbībam <br />
                45 Eur Ja neviens no ģimenes locekļiem neapmeklē nodarbības</p>
                <button onClick={() =>{window.location.href = whatsAppFitness}}><b>Pierakstīties</b></button>
            </div>
            <div className='price-block'>
                <h2>Pakalpojuma komplekts 5</h2>
                <h4>55 Eur</h4>
                <h6>Pilns abonements</h6>
                <p>Neierobežots apmeklējums uz jebkurā nodarbību</p>
                <button onClick={() =>{window.location.href = whatsAppKik}}><b>Pierakstīties</b></button>
            </div>
        </div>
        <div className='price-list-second'>    
            <div className='price-block'>
                <h2>Bērnu komplekts 1</h2>
                <h4>35 Eur</h4>
                <p>Nodarbības bērniem no 3 līdz 5 gadiem</p>
                <button onClick={() =>{window.location.href = whatsAppChildThreeKikbokss}}><b>Pierakstīties</b></button>
            </div>
            <div className='price-block'>
                <h2>Bērnu komplekts 2</h2>
                <h4>45 Eur</h4>
                <p>Nodarbības bērniem no 6 līdz 10 gadiem</p>
                <button onClick={() =>{window.location.href = whatsAppChildSixKikbokss}}><b>Pierakstīties</b></button>
            </div>
            <div className='price-block'>
                <h2>1 Apmeklejuma</h2>
                <h4>5 - 10 EUR</h4>
                <p>5 EUR Vienreizējs boksa zāles vai trenažieru zāles apmeklējums</p>
                <p>10 EUR Vienreizējs grupu treniņu apmeklējums</p>
                <button onClick={() =>{window.location.href = whatsAppOneWalk}}><b>Pierakstīties</b></button>
            </div>
            <div className='price-block'>
                <h2>Individuāla nodarbībā</h2>
                <h4>20 - 30 EUR</h4>
                <p>Individuālās apmācības cena ir atkarīga no trenera</p>
                <button onClick={() =>{window.location.href = whatsAppPersonal}}><b>Pierakstīties</b></button>
            </div>
            {/* <div className='price-block'>
                <h2></h2>
                <h4></h4>
                <p></p>
            </div> */}
            {/* <div className='price-block'>
                <h2></h2>
                <h4></h4>
                <p></p>
            </div> */}
        </div>
    </div>
  )
}
